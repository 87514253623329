<template>
  <v-card class="pa-0" height="400">
    <v-card-title class="pb-2">
      <v-text-field
        id="searchGroup"
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon text @click="cancel"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text class="pa-3 pt-0">
      <v-list dense>
        <v-list-item-group>
          <!-- new -->
          <v-list-item @click="save(-1)">
            <v-list-item-content style="color: orange">
              <v-list-item-title> New Family </v-list-item-title>
              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- existing -->
          <v-list-item
            v-for="family in filteredList"
            :key="family.id"
            @click="save(family.id)"
            :id="'family-picker::' + family_id"
            :style="{
              backgroundColor:
                family.id == value ? 'rgba(144, 213, 240, 0.23)' : '',
            }"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ family.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="error" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";
import utils from "@/assets/utils";
export default {
  props: {
    value: {
      type: String, //family_id
      default: "",
    },
  },
  watch: {
    value(n) {
      this.family_id = n;
    },
    /*family_id(newValue) {
      this.$emit("input", newValue);
    },*/
  },
  computed: {
    ...mapState(["invidata"]),
    filteredList() {
      let x = [];
      for (let i = 0; i < this.invidata.families.length; i++) {
        const family = this.invidata.families[i];

        let text = [family.name, family.name_override].join(" ");
        if (utils.searchMatch(this.searchText, text)) {
          x.push(family);
        }
      }
      return x;
    },
  },
  data() {
    return {
      searchText: "",
      family_id: "",
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save(id) {
      if (id == -1) {
        id = uuidv4();
      }
      this.$emit("input", id);
      this.cancel(); //just so we close
    },
  },
  mounted() {
    this.family_id = this.value;
    setTimeout(() => {
      document
        .getElementById("family-picker::" + this.family_id)
        .scrollIntoView();
    }, 1000);

    document.getElementById("searchGroup").focus();
  },
};
</script>

<style lang="scss" scoped></style>
