<template>
  <div>
    <div style="margin: auto">
      <div v-if="loading">
        <v-progress-circular
          style="position: fixed; left: calc(50% - 40px); top: calc(50% - 40px)"
          :size="80"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else-if="items.length">
        <div v-for="(item, index) in items" :key="item.id">
          <div
            v-if="
              index > 0 && items[index].deviceId != items[index - 1].deviceId
            "
            style="text-align: center; margin: 0px"
          >
            ···
          </div>
          <FormItem :value="item" class="item" />
          <!-- 
          
        --></div>
      </div>
      <div v-else>
        No form responses available. Make sure you have the form up and running
        (contact Alex).
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/api";
import FormItem from "./FormItem.vue";
import { mapState } from "vuex";
export default {
  components: {
    FormItem,
  },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(["invidata"]),
  },
  methods: {},
  async mounted() {
    this.loading = true;
    let x = await api.apiFetch("/form", { method: "GET" });

    if (x.ok) {
      let j = await x.json();
      this.items = j.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin: auto;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  max-width: 400px;
}
</style>
