<template>
  <div>
    <h1>Mesas</h1>
    <v-btn color="primary" depressed @click="test">Test</v-btn>
    <div>
      <h2>result</h2>
      {{ result }}
    </div>
    <div>
      {{ peoplee }}
    </div>
  </div>
</template>

<script>
import api from "@/assets/api";
export default {
  data() {
    return {
      result: "n/a",
      loading: false,
    };
  },
  computed: {
    peoplee() {
      return this.$store.getters.people;
    },
  },
  methods: {
    async test() {
      let x = await api.apiFetch("/test");
      if (x.ok) {
        this.result = await x.json();
      } else {
        this.result = "error";
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchAll");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
