<template>
  <v-app>
    <v-app-bar app color="primary" dense dark>
      <div class="d-flex align-center">
        <router-link to="/" style="color: white; text-decoration: none">{{
          tenants[0]?.title
        }}</router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn target="_blank" icon @click="showMenu = !showMenu">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="showMenu" temporary right app>
      <v-list dense nav>
        <!-- Web routes -->
        <v-list-item
          v-for="(route, index) in visible_routes"
          :key="index"
          link
          :to="route.to"
        >
          <v-list-item-icon>
            <v-icon>{{ route.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-bottom-navigation v-model="bottomNav" app grow mandatory>
      <v-btn
        :value="route.name"
        v-for="(route, index) in visible_routes"
        :key="index"
        link
        :to="route.to"
      >
        <span>{{ route.name }} </span>
        <v-badge
          overlap
          color="green"
          :content="route.badge"
          :value="route.badge > 0"
        >
          <v-icon>{{ route.icon }}</v-icon>
        </v-badge>
      </v-btn>
    </v-bottom-navigation>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import api from "@/assets/api";
import { mapGetters, mapState } from "vuex";
export default {
  name: "App",

  data() {
    return {
      showMenu: false,
      bottomNav: "people",
      tenants: [],
      routes: [
        //{ name: this.profileMenuText, to: "/auth", icon: "mdi-account" },
        // { name: "Home", to: "/", icon: "mdi-home" },
        { name: "Profile", to: "/settings", icon: "mdi-account", badge: 0 },
        { name: "Invitados", to: "/people", icon: "mdi-list-box", badge: 0 },
        {
          name: "Metrics",
          to: "/metrics",
          icon: "mdi-chart-box-outline",
          badge: 0,
        },
        { name: "Mesas", to: "/mesas", icon: "mdi-desk", badge: 0 },
        { name: "Form", to: "/form", icon: "mdi-table-check", badge: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters(["username"]),
    ...mapState(["token"]),
    visible_routes() {
      if (!this.username || !this.token) return []; //hide manus if no authenticated
      return this.routes;
    },
    profileMenuText() {
      if (this.username) {
        return this.username;
      } else {
        return "Log in";
      }
    },
  },
  methods: {
    async getFormData() {
      let x = await api.apiFetch("/form", { method: "GET" });
      if (x.ok) {
        let j = await x.json();
        let n = j.filter((a) => a.noted != "Y").length;
        this.routes.find((a) => a.name == "Form").badge = n;
      }
    },
    async getTenantData() {
      let x = await api.apiFetch("/tenants");
      if (x.ok) {
        this.tenants = await x.json();
      }
    },
  },
  mounted() {
    //this.$store.dispatch("fetchAll");
    this.getFormData();
    this.getTenantData();
    this.$store.dispatch("fetchAll"); //make sure we fetch guests data asap
  },
};
</script>

<style>
a,
.link {
  cursor: pointer;
  color: #0070d7;
  font-weight: 500;
  /*font-size: 10pt;*/
  text-decoration: none;
}

a:hover,
.link:hover {
  text-decoration: underline;
}
</style>
