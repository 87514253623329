<template>
  <div style="max-width: 800px; margin: auto">
    <!--saving dialog-->
    <v-dialog v-model="loading2" width="100">
      <v-card>
        <v-card-text
          style="margin: auto; text-align: center; padding-top: 20px"
        >
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- loading -->
    <v-progress-linear
      class="mt-3"
      v-if="loading"
      indeterminate
    ></v-progress-linear>

    <!-- form -->
    <div v-if="!loading" style="margin-bottom: 60px">
      <v-text-field
        label="name"
        v-model="person2.full_name"
        :readonly="readonly"
      ></v-text-field>

      <!-- toogles -->
      <div
        style="display: flex; justify-content: space-between; flex-wrap: wrap"
      >
        <!-- favorite -->
        <ToggleGroup
          title="Favorite"
          :default="'Null'"
          :readonly="readonly"
          v-model="person2.chosen"
          :items="[
            { icon: 'mdi-account', color: 'black', value: 'N' },
            { icon: 'mdi-star-half-full', color: 'black', value: 'Null' },
            { icon: 'mdi-star', color: 'black', value: 'Y' },
          ]"
        />

        <!-- confirmed -->
        <ToggleGroup
          title="Confirmed"
          v-model="person2.confirmed"
          :default="'Null'"
          :readonly="readonly"
          :items="[
            { icon: 'mdi-checkbox-blank-circle', color: 'red', value: 'N' },
            {
              icon: 'mdi-checkbox-blank-circle',
              color: 'purple',
              value: 'Null',
            },
            { icon: 'mdi-checkbox-blank-circle', color: 'green', value: 'Y' },
          ]"
        />

        <!-- bus -->
        <ToggleGroup
          title="Bus?"
          :default="'Null'"
          :readonly="readonly"
          v-model="person2.bus"
          :items="[
            { icon: 'mdi-bus', color: 'red', value: 'N' },
            {
              icon: 'mdi-bus',
              color: 'purple',
              value: 'Null',
            },
            { icon: 'mdi-bus', color: 'green', value: 'Y' },
          ]"
        />

        <!-- alergias -->
        <ToggleGroup
          title="Alergias"
          :default="'Null'"
          :readonly="readonly"
          v-model="person2.has_alergias"
          :items="[
            { icon: 'mdi-food-off', color: 'red', value: 'N' },
            {
              icon: 'mdi-food',
              color: 'purple',
              value: 'Null',
            },
            { icon: 'mdi-food', color: 'green', value: 'Y' },
          ]"
        />
      </div>
      <v-textarea
        v-if="person2.has_alergias != 'N'"
        outlined
        :readonly="readonly"
        rows="2"
        class="mt-2"
        label="Alergias a..."
        v-model="person2.alergias"
      >
      </v-textarea>

      <!-- menu -->
      <v-select
        label="Menu"
        v-model="person2.menu"
        :readonly="readonly"
        outlined
        :items="['ADULTO', 'INFANTIL', 'BEBE', 'STAFF']"
      >
      </v-select>

      <!-- family v2 -->
      <!-- pick family -->

      <div>
        <v-row>
          <v-col>
            <!-- family finder -->
            <v-text-field
              @click="showFamilyPicker = true && !readonly"
              label="Familia"
              v-if="invidata.families"
              readonly
              :value="familyName"
              hide-details
            ></v-text-field>
            <v-dialog
              v-model="showFamilyPicker"
              scrollable
              max-width="500"
              fullscreen
            >
              <FamilyPicker
                @cancel="showFamilyPicker = false"
                v-model="person2.family_id"
              />
              <!-- end family finder-->
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              label="owner"
              :readonly="readonly"
              :items="
                invidata.owners.map((x) => {
                  return { text: x.name, value: x.id };
                })
              "
              v-model="person2.owner_id"
            >
            </v-select>
          </v-col>
          <v-col>
            <!-- group find -->
            <v-text-field
              readonly
              label="Group"
              @click="showGroupPicker = true && !readonly"
              :value="
                invidata.groups.find((x) => x.id == person2.group_id)?.name
              "
            ></v-text-field>
            <v-dialog
              v-model="showGroupPicker"
              scrollable
              max-width="500"
              fullscreen
            >
              <GroupPicker
                @cancel="showGroupPicker = false"
                v-model="person2.group_id"
              />
            </v-dialog>
            <!-- end group find -->
          </v-col>
        </v-row>
      </div>

      <!-- comments -->
      <v-textarea
        :readonly="readonly"
        outlined
        rows="2"
        label="Comments"
        v-model="person2.comments"
      >
      </v-textarea>

      <!-- hide-->
      <v-switch
        true-value="Y"
        false-value="N"
        :readonly="readonly"
        v-model="person2.hide"
        label="Archivar (ocultar)"
      ></v-switch>

      <div v-if="false" style="margin-top: 80px">
        <pre>{{ JSON.stringify(person2, null, 2) }}</pre>
      </div>
    </div>

    <!-- save -->
    <div
      style="
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 60px;
        padding: 15px 10px;
        background-color: white;
        border-top: 1px solid #ccc;
      "
    >
      <div style="max-width: 400px; margin: auto">
        <v-btn
          block
          color="primary"
          depressed
          :disabled="readonly"
          @click="save"
          >Save</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/api";
import { mapState } from "vuex";
import ToggleGroup from "@/components/ToggleGroup.vue";
import FamilyPicker from "./FamilyPicker.vue";
import GroupPicker from "./GroupPicker.vue";

export default {
  components: {
    ToggleGroup,
    FamilyPicker,
    GroupPicker,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    /**
     * If this object is provided, we will use this instead of reading the person details from the store
     */
    override_item: {
      type: Object,
      deafult() {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      deafult: false,
    },
  },
  computed: {
    ...mapState(["invidata"]),
    familyName() {
      if (!this.person2.family_id) return "";
      let existing_family = this.invidata.families.find(
        (x) => x.id == this.person2.family_id
      );
      if (existing_family) return existing_family.name;

      return "Family of " + this.person2.full_name;
    },
    person() {
      //in case things still loading
      if (!this.invidata || !this.invidata.people) return {};

      //in case an override is sent (so the new values are to be taken from the prop, and not from the store)
      if (this.override_item && this.override_item.id) {
        return this.override_item;
      }

      //else, find the current values in the store. If not found (unexpected) we return empty object
      let x = this.invidata.people.find((x) => x.id == this.id);
      let final = x || this.override_item;
      if (!x) final = {};
      return final;

      /*
      for (let i = 0; i < this.invidata.people.length; i++) {
        const person = this.invidata.people[i];
        if (person.id == this.id) {
          return person;
        }
      }
      return {};
      */
    },
  },
  watch: {
    person2(newValue) {
      this.$emit("change", newValue);
    },

    person(newValue) {
      this.person2 = JSON.parse(JSON.stringify(newValue));
      //in case data not yet loaded:
      if (!this.invidata || this.invidata.families || this.invidata.groups) {
        return;
      }

      this.group_id = this.invidata.families.find(
        (x) => x.id == newValue.family_id
      ).group_id;

      this.owner_id = this.invidata.groups.find(
        (x) => x.id == this.group_id
      ).owner_id;
    },
  },
  data() {
    return {
      loading: false,
      loading2: false,
      showFamilyPicker: false,
      showGroupPicker: false,
      person2: {},
      group_id: "",
      owner_id: "",
    };
  },
  methods: {
    async save() {
      this.loading2 = true;
      let x = await api.apiFetch("/people", {
        method: "POST",
        body: JSON.stringify(this.person2),
      });
      if (!x.ok) {
        console.error("Error saving");
      } else {
        let j = await x.json();
        console.log("Saved", j);

        //propagate change:
        let xx = JSON.parse(JSON.stringify(this.invidata));

        let index = xx.people.findIndex((x) => x.id == this.id);
        xx.people[index] = JSON.parse(JSON.stringify(this.person2));

        this.$store.commit("SET_INVIDATA", xx);
        this.$emit("saved");
      }
      this.loading2 = false;
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchAll");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
