import Vue from "vue";
import VueRouter from "vue-router";

import AuthView from "../views/AuthView.vue";
import PeopleView from "../views/PeopleView.vue";
import GroupsView from "../views/GroupsView.vue";
import MesasView from "../views/MesasView.vue";

import FamilyPage from "../views/Edit/FamilyPage.vue";
import PersonPage from "../views/Edit/PersonPage.vue";
import MetricsView from "../views/MetricsView.vue";
import MetricsView2 from "../views/MetricsView2.vue";
import SettingsView from "../views/SettingsView.vue";
import FormView from "../views/Form/FormView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/people",
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/form",
    name: "FormView",
    component: FormView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/people",
    name: "people",
    component: PeopleView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/metrics",
    name: "metrics",
    component: MetricsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/metrics2",
    name: "metrics2",
    component: MetricsView2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/family/:id",
    name: "family",
    component: FamilyPage,
    props: (route) => ({
      id: String(route.params.id),
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/person/:id",
    name: "person",
    component: PersonPage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/groups",
    name: "groups",
    component: GroupsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mesas",
    name: "mesas",
    component: MesasView,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth) {
    next();
  } else {
    const token = localStorage.token;
    if (!token) next("/auth?redirect=" + to.path);
    else next();
  }
});

export default router;
