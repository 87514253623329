<template>
  <div style="margin: 10px 0px">
    <h4 v-if="title">{{ title }}</h4>
    <v-btn-toggle v-model="toggle" mandatory>
      <v-btn v-for="item in items" :key="item.value" :disabled="readonly">
        <v-icon :color="item.color">{{ item.icon }}</v-icon>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "Null",
    },
    readonly: {
      type: Boolean,
      deafult: false,
    },
    default: { String, default: "" },
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default() {
        return [
          { icon: "mdi-heart", color: "red", value: "N" },
          { icon: "mdi-heart", color: "purple", value: "Null" },
          { icon: "mdi-heart", color: "green", value: "Y" },
        ];
      },
    },
  },
  watch: {
    value(newValue) {
      let x = this.items.findIndex((a) => a.value == newValue);
      if (x != -1) {
        this.toggle = x;
      } else {
        this.toggle = this.items.findIndex((a) => a.value == this.default); // if value is not one of the options, set default
      }
    },
    toggle(newValue) {
      if (this.items && this.items[newValue] && this.items[newValue].value) {
        this.$emit("input", this.items[newValue].value);
      }
    },
  },
  data() {
    return {
      toggle: 0,
    };
  },
  mounted() {
    this.toggle = this.items.findIndex((x) => x.value == this.value);
  },
};
</script>

<style lang="scss" scoped></style>
