import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { mutations } from "./types";

import api from "@/assets/api";

const store = new Vuex.Store({
  state: {
    count: 0,
    token: localStorage.token || "",

    invidata: { families: [], owners: [], groups: [], people: [] },
  },
  getters: {
    username: (state) => {
      return state.token.split("::")[0];
    },
    /**
     * people excluding hides, and with all their info available (adding keys with objects: person.family, person.group, person.owner)
     * The invidata object already makes sure that all people have a family and a group.
     * @param {*} state
     * @returns
     */
    people: (state) => {
      let x = [];

      for (let i = 0; i < state.invidata.people.length; i++) {
        let person = { ...state.invidata.people[i] };
        if (person.hide) continue; //skip hide

        //get family
        let family = {
          ...state.invidata.families.find((x) => x.id == person.family_id),
        };
        if (family.hide) continue; //skip hide

        //get group
        let group = {
          ...state.invidata.groups.find((x) => x.id == person.group_id),
        };

        //get owner
        let owner = {
          ...state.invidata.owners.find((x) => x.id == person.owner_id),
        };

        person.family = family;
        person.group = group;
        person.owner = owner;

        x.push(person);
      }

      return x;
    },
  },
  mutations: {
    [mutations.SET_TOKEN](state, value) {
      state.token = value;
      localStorage.token = value; //let's store it for a while
    },

    [mutations.SET_INVIDATA](state, value) {
      state.invidata = value;
    },
  },
  actions: {
    somethingAsync({ commit }, value) {
      commit(mutations.SET_TEST1, value);
    },
    /**
     * This fetches the data from the api, and creates the `invidata`, which is the raw data with some fixes:
     * - Generates a family name for families that are not created per se. If there is no family_id, it maps it to family "unknown" = Unknown
     * - Similarly, if groups are missing it creates them with a new name.
     * Note: those generated families and groups would still not existing in the spreadsheet, but they get created once modified and saved.
     */
    async fetchAll({ commit }) {
      //Let's fetch the different objects async
      let invidata = {};
      let f1 = async (path) => {
        //This function takes the path, fetches the data and updates the invidata with an array of the same name as the path
        let x = await api.apiFetch(path, { method: "GET" });
        if (!x.ok) return [];
        invidata[path.replace(/\//g, "")] = await x.json();
      };

      await Promise.allSettled([
        f1("/groups"),
        f1("/families"),
        f1("/people"),
        f1("/owners"),
      ]);

      //Now backfill and fix (add missing families and groups)
      let people = invidata.people;
      let families = invidata.families;
      let groups = invidata.groups;
      let owners = invidata.owners;

      //TODO: let's keep this just temporally. It's because of legacy of when group_id was a family feature, but that shouldnt be part of the db anymore
      for (let i = 0; i < families.length; i++) {
        if (families[i].group_id) delete families[i].group_id;
      }

      //let's check each person to find and fix people orphan of 'real' families and groups
      for (let i = 0; i < people.length; i++) {
        let person = people[i];

        //fix empty / new groups (empty: no group_id, new: there is a group_id, but doesn't match any known group)
        if (!person.group_id) person.group_id = "unknown";
        if (!groups.find((x) => x.id == person.group_id)) {
          groups.push({
            id: person.group_id,
            name:
              person.group_id == "unknown"
                ? "Unknown group"
                : "Group " + person.group_id,
          });
        }

        //fix empty / new family   (empty: no family_id, new: there is a family_id, but doesn't match any known family)
        if (!person.family_id) person.family_id = "unknown";
        if (!families.find((x) => x.id == person.family_id)) {
          let people_in_family = people
            .filter((x) => x.family_id == person.family_id)
            .map((x) => x.full_name);

          let name =
            person.family_id == "unknown"
              ? "Unknown family"
              : people_in_family.join(" / "); //Generate a name by concatenating the names of the people with the same family

          if (people_in_family.length < 2) {
            name = "Famly of " + name;
          }

          families.push({
            id: person.family_id,
            name: name,
          });
        }

        //fix empty / new owner
        if (!person.owner_id) person.owner_id = "unknown";
        if (!owners.find((x) => x.id == person.owner_id)) {
          owners.push({
            id: person.owner_id,
            name:
              person.owner_id == "unknown"
                ? "Unknown owner"
                : "Owner " + person.owner_id,
          });
        }
      }
      commit(mutations.SET_INVIDATA, invidata);
    },
  },
  modules: {},
});

export default store;
