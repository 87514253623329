<template>
  <v-expansion-panel>
    <!-- edit dialog -->
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" style="max-height: 60px">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Familia {{ family.id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="false">
            <v-btn dark text @click="dialog = false"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-3">
          <FamilyPage :id="family.id" @saved="dialog = false" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- HEADER -->
    <v-expansion-panel-header disable-icon-rotate>
      {{ family.name || family.people.map((x) => x.full_name).join(" + ") }} ({{
        family.people.filter((x) => x.confirmed == "Y").length +
        "/" +
        family.people.length
      }})

      <!-- actions -->
      <template v-slot:actions>
        <!-- hidden -->
        <v-icon color="red" v-if="family.hide == 'Y'">mdi-eye-off</v-icon>

        <!-- invitation: not-send + not-address -->
        <v-icon
          color="red"
          v-else-if="family.invitation_sent != 'Y' && !family.address"
          @click.prevent="startQuickEditFamily(family.family)"
          >mdi-email-edit</v-icon
        >
        <!-- invitation:not-send, but has address-->
        <v-icon
          color="orange"
          v-else-if="family.invitation_sent != 'Y'"
          @click.prevent="startQuickEditFamily(family.family)"
          >mdi-email</v-icon
        >
        <!-- people not yet confirmed -->
        <v-icon
          color="purple"
          v-else-if="
            family.people.filter(
              (x) => x.confirmed != 'Y' && x.confirmed != 'N'
            ).length
          "
          >mdi-alert-circle</v-icon
        >
        <!-- all good -->
        <v-icon color="success" v-else>mdi-check</v-icon>
      </template>
    </v-expansion-panel-header>

    <!-- CONTENT -->
    <v-expansion-panel-content>
      <v-list subheader>
        <PersonListItem
          v-for="(person, index) in family.people"
          :key="'person::' + person.id"
          v-model="family.people[index]"
        ></PersonListItem>
      </v-list>
      <!--      <router-link :to="'/family/' + family.id">Editar familia</router-link> -->
      <span class="link" @click="dialog = !dialog">Detalles familia</span>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import PersonListItem from "@/views/PeopleList/PersonListItem.vue";
import FamilyPage from "@/views/Edit/FamilyPage.vue";
export default {
  components: {
    PersonListItem,
    FamilyPage,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          people: [],
        };
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.family = newValue;
      },
    },
    family: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {
      family: { people: [] },
      dialog: false,
    };
  },
  mounted() {
    this.family = this.value;
  },
};
</script>

<style lang="scss" scoped></style>
