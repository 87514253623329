import { render, staticRenderFns } from "./FamilyPicker.vue?vue&type=template&id=3bf32d4f&scoped=true&"
import script from "./FamilyPicker.vue?vue&type=script&lang=js&"
export * from "./FamilyPicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf32d4f",
  null
  
)

export default component.exports