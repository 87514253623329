<template>
  <div style="max-width: 600px; margin: auto">
    <h1>Invitados</h1>

    <!-- add new person dialog -->
    <v-dialog v-model="showNewPersonDialog" scrollable fullscreen>
      <v-card>
        <v-card-title>
          Add new person
          <v-spacer> </v-spacer>
          <v-btn icon @click="showNewPersonDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <PersonPage
            :override_item="{ id: String(this.newPersonId) }"
            @saved="onPersonCreated()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <div>
      <v-text-field
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        hide-details
      ></v-text-field>
    </div>

    <!-- advanced filters-->
    <div style="margin: 10px 0px">
      <div>
        <span
          class="link"
          style="font-size: 0.8rem"
          @click="seeAdvancedFilters = !seeAdvancedFilters"
          >{{
            seeAdvancedFilters
              ? "Ocultar filtros avanzados"
              : "Filtros avanzados"
          }}</span
        >
      </div>

      <!-- owner / group filters-->
      <div v-if="seeAdvancedFilters">
        <FilterChips v-model="filters" :items="all_items" />
      </div>

      <!-- insights text -->
      <div style="font-size: 90%" v-if="!loading">
        {{
          items.reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.people.filter((x) => x.confirmed == "Y").length,
            0
          )
        }}
        confirmados,
        {{
          items.reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.people.filter(
                (x) => x.confirmed != "Y" && x.confirmed != "N"
              ).length,
            0
          )
        }}
        en duda,
        {{
          items.reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.people.filter((x) => x.confirmed == "N").length,
            0
          )
        }}
        no vienen (de
        {{
          items.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.people.length,
            0
          )
        }})
      </div>
      <div style="font-size: 90%" v-if="!loading">
        {{ items.filter((x) => x.invitation_sent != "Y").length }} invitaciones
        pendientes (de {{ items.length }})
      </div>
      <div style="font-size: 90%" v-if="!loading">
        {{
          items.reduce(
            (acc, curr) =>
              acc +
              curr.people.filter((x) => x.confirmed == "Y" && x.bus == "Y")
                .length,
            0
          )
        }}
        de los confirmados cogen bus
      </div>
      <div style="font-size: 90%" v-if="!loading">
        {{
          items.reduce(
            (acc, curr) =>
              acc +
              curr.people.filter(
                (x) => x.confirmed == "Y" && x.has_alergias == "Y"
              ).length,
            0
          )
        }}
        confirmados tienen alergias
      </div>
    </div>

    <!-- summary chips //TODO: make them clickable and apply filters accordinagly -->
    <div style="margin-bottom: 5px" v-if="!loading">
      <v-chip small class="mr-1 mb-1" color="green" outlined>
        <v-icon small color="green" class="mr-1">mdi-account-group</v-icon>
        {{
          items.reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.people.filter((x) => x.confirmed == "Y").length,
            0
          )
        }}</v-chip
      >
      <v-chip
        small
        class="mr-1 mb-1"
        color="purple"
        :outlined="!filters2.pending"
        :dark="filters2.pending"
        @click="filters2.pending = !filters2.pending"
      >
        <v-icon small class="mr-1">mdi-account-group</v-icon>
        {{
          items.reduce(
            (accumulator, currentValue) =>
              accumulator +
              currentValue.people.filter(
                (x) => x.confirmed != "Y" && x.confirmed != "N"
              ).length,
            0
          )
        }}</v-chip
      >
      <v-chip
        small
        class="mr-1 mb-1"
        color="primary"
        :outlined="!filters2.bus"
        :dark="filters2.bus"
        @click="filters2.bus = !filters2.bus"
        ><v-icon left small class="mr-1">mdi-bus</v-icon>
        {{
          items.reduce(
            (acc, curr) =>
              acc +
              curr.people.filter((x) => x.confirmed == "Y" && x.bus == "Y")
                .length,
            0
          )
        }}</v-chip
      >

      <v-chip
        small
        class="mr-1 mb-1"
        color="green"
        :outlined="!filters2.pending_invitation"
        :dark="filters2.pending_invitation"
        @click="filters2.pending_invitation = !filters2.pending_invitation"
        ><v-icon small class="mr-1">mdi-email</v-icon>
        {{ items.filter((x) => x.invitation_sent == "Y").length }}/{{
          items.length
        }}</v-chip
      >
      <v-chip
        small
        class="mr-1 mb-1"
        color="red"
        :outlined="!filters2.alergies"
        :dark="filters2.alergies"
        @click="filters2.alergies = !filters2.alergies"
        ><v-icon small class="mr-1">mdi-food-off</v-icon>
        {{
          items.reduce(
            (acc, curr) =>
              acc +
              curr.people.filter(
                (x) => x.confirmed == "Y" && x.has_alergias == "Y"
              ).length,
            0
          )
        }}</v-chip
      >
    </div>

    <!--add button-->
    <v-fab-transition>
      <v-btn
        fab
        bottom
        right
        fixed
        color="primary"
        @click="addNewItem"
        :loading="loading"
        class="mb-13"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- loading -->
    <div v-if="loading">
      <v-progress-linear v-if="false" indeterminate></v-progress-linear>
      <v-progress-circular
        style="position: fixed; left: calc(50% - 40px); top: calc(50% - 40px)"
        :size="80"
        indeterminate
      ></v-progress-circular>
    </div>

    <!-- actual people -->
    <div>
      <v-expansion-panels :value="expanded_index">
        <FamilyListItem
          v-for="(item, index) in items"
          :key="'item::' + item.id"
          v-model="items[index]"
        />
      </v-expansion-panels>
    </div>

    <!-- space buffer -->
    <div style="height: 60px"></div>
  </div>
</template>

<script>
//import api from "@/assets/api";
import utils from "@/assets/utils";
import { v4 as uuidv4 } from "uuid";

import FamilyListItem from "@/views/PeopleList/FamilyListItem.vue";
import FilterChips from "@/views/PeopleList/FilterChips.vue";
import PersonPage from "@/views/Edit/PersonPage.vue";

import { nextTick } from "vue";
import { mapState } from "vuex";
export default {
  components: {
    FamilyListItem,
    FilterChips,
    PersonPage,
  },
  data() {
    return {
      searchText: "",
      loading: false,
      expanded_index: null,
      seeAdvancedFilters: false,
      filters: {
        owners: [],
        groups: [],
      },
      filters2: {
        confirmed: false,
        pending: false,
        bus: false,
        pending_invitation: false,
        alergies: false,
      },

      //new person stuff
      newPersonId: "",
      showNewPersonDialog: false,
    };
  },
  watch: {
    seeAdvancedFilters(newValue) {
      if (!newValue) this.filters = { owners: [], groups: [] };
    },
    searchText() {
      this.checkAutoExpand();
    },
    filters: {
      deep: true,
      handler() {
        this.checkAutoExpand();
      },
    },
  },
  computed: {
    ...mapState(["invidata"]),
    people() {
      return this.$store.getters.people;
    },

    all_items() {
      //this takes store people object, and organizes in families, marking as family.visible = false the families where no person matches the filters.
      //the idea is that we will always display full families. If one person matches the filter, we will see the full family.
      if (this.loading) return [];

      let output = []; //output: one per family, with {people, group}

      for (let i = 0; i < this.people.length; i++) {
        let person = { ...this.people[i] };
        if (person.hide == "Y") continue;
        if (person.family.hide == "Y") continue;

        //

        //find family

        //if there is no family, let's create an empty one around the family_id of the person
        let family =
          person.family && person.family.id
            ? { ...person.family }
            : { id: person.family_id };

        let keywords = [family.name, person.full_name, person.group.name].join(
          " "
        );
        let match = utils.searchMatch(this.searchText, keywords);
        //if (!match) continue;

        let matchOwner =
          this.filters.owners.length == 0 ||
          this.filters.owners.includes(person.owner.id);

        let matchGroup =
          this.filters.groups.length == 0 ||
          this.filters.groups.includes(person.group.id);

        let matchInvitation =
          this.filters2.pending_invitation == false ||
          (this.filters2.pending_invitation && person.invitation_sent != "Y");

        let matchAlergies =
          this.filters2.alergies == false ||
          (this.filters2.alergies && person.has_alergias == "Y");

        let matchBus =
          this.filters2.bus == false ||
          (this.filters2.bus && person.bus == "Y");

        let matchPending =
          this.filters2.pending == false ||
          (this.filters2.pending &&
            person.confirmed != "Y" &&
            person.confirmed != "N");

        let f = output.findIndex((x) => x.id == family.id);
        if (f == -1) {
          family.people = [];
          family.visible = false;
          output.push(family);
          f = output.findIndex((x) => x.id == family.id);
        }
        output[f].people.push(person);

        if (
          match &&
          matchOwner &&
          matchGroup &&
          matchInvitation &&
          matchAlergies &&
          matchBus &&
          matchPending
        )
          output[f].visible = true;
      }

      return output;
    },
    items() {
      let output = [];
      for (let i = 0; i < this.all_items.length; i++) {
        const item = { ...this.all_items[i] };
        if (item.visible) output.push(item);
      }
      return output;
    },
  },
  methods: {
    onPersonCreated() {
      this.$store.dispatch("fetchAll");
      this.showNewPersonDialog = false;
    },
    addNewItem() {
      this.newPersonId = uuidv4();
      this.showNewPersonDialog = true;
    },
    checkAutoExpand() {
      nextTick(() => {
        //when searchText changes, if only one result we expand that result
        if (this.items.length == 1) this.expanded_index = 0;
        if (this.items.length > 1) this.expanded_index = null;
      });
    },
  },
  async mounted() {
    //this.fetchAll();
    this.loading = true;
    await this.$store.dispatch("fetchAll");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
</style>
