<template>
  <v-card class="pa-0" height="400">
    <v-card-title class="pb-2">
      <v-text-field
        id="searchGroup"
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon text @click="cancel"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text class="pa-3 pt-0">
      <v-list dense>
        <v-list-item-group>
          <!-- new -->
          <v-list-item @click="save(-1)">
            <v-list-item-content style="color: orange">
              <v-list-item-title> New Guest </v-list-item-title>
              <v-list-item-subtitle> Add as new person </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- existing people -->
          <v-list-item
            v-for="person in filteredList"
            :key="person.id"
            @click="save(person.id)"
            :id="'person-picker::' + person_id"
            :style="{
              backgroundColor:
                person.id == value ? 'rgba(144, 213, 240, 0.23)' : '',
            }"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ person.full_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ person.group_id }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="error" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import utils from "@/assets/utils";
export default {
  props: {
    value: {
      type: String, //person_id
      default: "",
    },
  },
  watch: {
    value(n) {
      this.person_id = n;
    },
    /*person_id(newValue) {
      this.$emit("input", newValue);
    },*/
  },
  computed: {
    ...mapState(["invidata"]),
    filteredList() {
      let x = [];
      for (let i = 0; i < this.invidata.people.length; i++) {
        const person = this.invidata.people[i];

        let text = [person.full_name, person.group_id].join(" ");
        if (utils.searchMatch(this.searchText, text)) {
          x.push(person);
        }
      }
      return x;
    },
  },
  data() {
    return {
      searchText: "",
      person_id: "",
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save(id) {
      this.$emit("input", id);
      this.cancel(); //just so we close
    },
  },
  mounted() {
    this.person_id = this.value;
    setTimeout(() => {
      document
        .getElementById("person-picker::" + this.person_id)
        .scrollIntoView();
    }, 1000);

    document.getElementById("searchGroup").focus();
  },
};
</script>

<style lang="scss" scoped></style>
