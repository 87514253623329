<template>
  <div>
    <v-dialog v-model="showPersonPicker" scrollable max-width="500">
      <PersonPicker
        @cancel="showPersonPicker = false"
        @input="matchPerson($event)"
      />
    </v-dialog>

    <v-dialog v-model="showMatchPreview" scrollable fullscreen>
      <v-card>
        <v-card-title>
          <v-toolbar-title>Preview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showMatchPreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- tab selector -->
          <div v-if="original_person.full_name">
            <span
              class="link"
              @click="tab = 1"
              :style="{ fontWeight: tab == 1 ? 'bold' : '300' }"
              >Before</span
            >
            |
            <span
              class="link"
              @click="tab = 2"
              :style="{ fontWeight: tab == 2 ? 'bold' : '300' }"
              >After</span
            >
            |
            <span
              class="link"
              @click="tab = 3"
              :style="{ fontWeight: tab == 3 ? 'bold' : '300' }"
            >
              Diff
            </span>
          </div>

          <!-- original person info -->
          <PersonPage
            v-show="tab == 1"
            :readonly="true"
            :id="original_person.id"
          />

          <!-- person page with form data -->
          <PersonPage
            v-show="tab == 2"
            :id="form_person.id"
            :override_item="form_person"
            @change="final_person = $event"
            @saved="onSavedFormPerson()"
          />

          <!-- diff -->
          <div v-show="tab == 3">
            <!-- diff -->
            <div style="margin: 10px 0px">
              <div v-for="(v, k) of values_diff" :key="k">
                <b>{{ k }}</b>
                <p class="previous">{{ v.before }}</p>
                <p class="updated">{{ v.after }}</p>
              </div>
            </div>
            <div v-if="false">
              <table style="width: 100%; table-layout: fixed">
                <tr v-for="(v, k) of values_diff" :key="k">
                  <td style="font-weight: bold">{{ k }}</td>
                  <td class="previous">{{ v.before }}</td>
                  <td class="updated">{{ v.after }}</td>
                </tr>
              </table>
            </div>
          </div>

          <!-- previous -->

          <!-- json-->
          <div v-if="false">
            <p style="color: red">
              {{ JSON.stringify(original_person) }}
            </p>
            <p style="color: green">
              {{ JSON.stringify(form_person) }}
            </p>
            <p style="color: blue">
              {{ JSON.stringify(value) }}
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <h3>{{ item.name }} {{ item.last_name }}</h3>
    <div v-if="item.phone" style="margin-top: 8px; font-size: 90%">
      <b>Phone</b> {{ item.phone }}
    </div>

    <div style="margin-top: 8px">
      <!-- coming -->
      <v-chip small class="mr-1" :color="getColor('coming')" outlined>
        <v-icon small left>mdi-account-multiple</v-icon>
        {{
          item.coming == "Y"
            ? "Voy!"
            : item.coming == "N"
            ? "No voy"
            : "Aún no sé"
        }}
      </v-chip>
      <!--bus -->
      <v-chip small class="mr-1" :color="getColor('bus')" outlined>
        <v-icon small left>mdi-bus</v-icon>
        {{
          item.bus == "Y" ? "Cojo bus" : item.bus == "N" ? "No gracias" : "???"
        }}
      </v-chip>
      <!--alergia -->
      <v-chip small class="mr-1" :color="getColor('has_alergias')" outlined>
        <v-icon small left>mdi-food</v-icon>
        {{
          item.has_alergias == "Y"
            ? "Tengo alergias!"
            : item.has_alergias == "N"
            ? "No alergias"
            : "??"
        }}
      </v-chip>
    </div>

    <div
      style="margin-top: 8px; font-size: 95%"
      v-if="item.has_alergias == 'Y'"
    >
      <b>Alergias: </b>{{ item.alergias }}
    </div>

    <div
      v-if="item.message"
      style="margin-top: 10px; font-size: 95%; overflow-wrap: break-word"
    >
      <b>Mensaje: </b>
      {{ item.message }}
    </div>

    <div style="display: flex; justify-content: space-between">
      <div style="font-size: 9pt; margin-top: 5px; align-self: end">
        {{ item.timestamp }}
      </div>

      <div style="display: flex">
        <v-btn icon @click="contact_match()">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-progress-circular
          v-if="loading"
          indeterminate
          size="20"
          width="2"
          color="primary"
          class="mr-2"
        ></v-progress-circular>

        <v-checkbox
          v-else
          v-model="noted"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/api";
import PersonPicker from "../Edit/PersonPicker.vue";
import PersonPage from "@/views/Edit/PersonPage.vue";
import { mapState } from "vuex";

export default {
  components: {
    PersonPicker,
    PersonPage,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState(["invidata"]),
  },
  watch: {
    noted(newValue) {
      if (newValue) this.item.noted = "Y";
      if (!newValue) this.item.noted = "N";
      if (this.loaded) this.push();
    },
    final_person: {
      deep: true,
      handler() {
        this.refreshDiff();
      },
    },
  },
  data() {
    return {
      item: {},
      original_person: {},
      form_person: {}, //original
      final_person: {}, //this one gets refreshed
      values_diff: {}, //diff between original_person and final_person
      loading: false,
      noted: false,
      loaded: false,
      showPersonPicker: false,
      showMatchPreview: false,

      tab: 2,
    };
  },
  methods: {
    onSavedFormPerson() {
      this.showMatchPreview = false;

      this.item.person_id = this.form_person.id;
      this.noted = "Y"; //this pushes the data when changed
    },
    matchPerson(id) {
      let person;
      if (id == -1) {
        person = {};
      } else {
        person = this.invidata.people.find((x) => x.id == id);
      }
      this.original_person = { ...person };
      this.showPersonPicker = false;
      this.showMatchPreview = true;

      this.form_person = {
        id: this.original_person.id || this.value.id, //let's keep the id from the form (it's a unique id)
        family_id: this.original_person.family_id || this.value.deviceId, //let's use the deviceId, so we group together people from the same form submission.
        full_name: this.value.name + " " + this.value.last_name,
        group_id: this.original_person.group_id || "",
        owner_id: this.original_person.owner_id || "",
        confirmed: this.value.coming,
        chosen: this.original_person.chosen || "Null",
        hide: this.original_person.hide || "N",
        bus: this.value.bus,
        bus1: this.boolean2value(this.value.bus1),
        bus2: this.boolean2value(this.value.bus2),
        bus3: this.boolean2value(this.value.bus3),
        bus4: this.boolean2value(this.value.bus4),
        bus_tbc: "",
        flag: "",
        has_alergias: this.value.has_alergias,
        alergias: this.value.alergias,
        menu: this.original_person.menu || "",
      };
      this.final_person = { ...this.form_person };
    },
    refreshDiff() {
      let diffKeys = [
        "full_name",
        "confirmed",
        "bus",
        "has_alergias",
        "alergias",
        "menu",
        "group_id",
        "family_id",
        "hide",
      ];

      let values_diff = {};
      for (let i = 0; i < diffKeys.length; i++) {
        const k = diffKeys[i];
        values_diff[k] = {
          before: this.original_person[k],
          after: this.final_person[k],
        };
      }
      this.values_diff = { ...values_diff };
    },
    boolean2text(v) {
      if (v == "Y" || String(v).toLowerCase() == "true" || v === true) {
        return "Yes";
      }
      if (v == "N" || String(v).toLowerCase() == "false" || v === false) {
        return "No";
      }
      return "???";
    },
    boolean2value(b) {
      if (String(b).toLowerCase() == "true") return "Y";
      if (String(b).toLowerCase() == "false") return "N";
      return "";
    },
    contact_match() {
      this.showPersonPicker = true;
    },
    getColor(k) {
      let v = this.item[k];
      if (k == "coming") {
        if (v == "Y") return "green";
        if (v == "N") return "red";
      }
      if (k == "bus") {
        if (v == "Y") return "blue";
        if (v == "N") return "grey";
      }
      if (k == "has_alergias") {
        if (v == "Y") return "red";
        if (v == "N") return "grey";
      }
      return "purple";
    },
    toggleNoted() {
      console.log("noted clicked", this.noted);
    },
    async push() {
      console.log("push");

      if (!this.item.id) return;
      this.loading = true;
      let x = await api.apiFetch("/form", {
        method: "POST",
        body: JSON.stringify(this.item),
      });

      if (x.ok) {
        let j = await x.json();
        console.log("jjjjj", j);
        this.item = j;
      } else {
        alert("Error. Not saved", x);
      }

      this.loading = false;
    },
  },

  mounted() {
    this.item = this.value;
    this.noted = this.item.noted == "Y";

    setTimeout(() => {
      this.loaded = true;
    }, 1000);
  },
};
</script>

<style scoped>
.previous {
  color: red;
  text-decoration: line-through;
  margin-bottom: 0px;
}
.updated {
  color: green;
}
</style>
