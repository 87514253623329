<template>
  <div>
    <h1>
      Hola {{ username }}
      <span class="link" style="font-size: 10pt" @click="logout()"
        >Cerrar sesión</span
      >
    </h1>

    <!-- timer -->
    <div v-if="tenants.length && tenants[0].date">
      <CountdownTimer :value="tenants[0].date" />
      <!-- 2023-06-03T11:15:00.000Z-->
    </div>

    <!-- useful links-->
    <div>
      <!-- tenants data-->
      <div>
        <div v-for="tenant in tenants" :key="tenant.id">
          <h2>{{ tenant.title }}</h2>
          <ul>
            <li>
              <a
                :href="
                  'https://docs.google.com/spreadsheets/d/' +
                  tenants[0].db +
                  '/edit#gid=0'
                "
                target="_blank"
                >Database (Google Sheets)</a
              >
            </li>
            <li v-if="tenant.form != tenant.db">
              <a
                :href="
                  'https://docs.google.com/spreadsheets/d/' +
                  tenants[0].form +
                  '/edit#gid=0'
                "
                target="_blank"
                >Formulario (Google Sheets)</a
              >
            </li>
            <li v-if="tenant.website">
              <a :href="tenant.website" target="_blank">Página web</a>
            </li>
          </ul>

          <div style="margin-top: 20px">
            API Key para
            <a
              :href="
                'https://formulario-boda.netlify.app/#/?id=' +
                tenant.form_api_key
              "
              target="_blank"
              >el formulario</a
            >: <br />
            <span
              style="
                font-size: 80%;
                font-family: 'Courier New', Courier, monospace;
                word-break: break-all;
              "
              >{{ tenant.form_api_key }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div id="buffer" style="height: 40px"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mutations } from "@/store/types";
import CountdownTimer from "@/components/CountdownTimer.vue";
import api from "@/assets/api";

export default {
  name: "HomeView",

  components: {
    CountdownTimer,
  },
  data() {
    return {
      tenants: [],
    };
  },
  computed: {
    ...mapGetters(["username"]),
  },
  methods: {
    logout() {
      this.$store.commit(mutations.SET_TOKEN, "");
      this.$router.go(this.$route.fullPath);
    },
  },
  async mounted() {
    let x = await api.apiFetch("/tenants");
    if (x.ok) {
      this.tenants = await x.json();
    }
  },
};
</script>
