<template>
  <div>
    <pre>{{ JSON.stringify(metrics, null, 2) }}</pre>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["invidata"]),
    metrics() {
      let byOwner = {};
      let owners = this.invidata.owners.map((x) => x.name);
      owners.unshift("total");

      for (let i = 0; i < owners.length; i++) {
        const o = owners[i];
        byOwner[o] = {
          people: {
            total: 0,
            confirmed: 0,
            pending: 0,
            not_coming: 0,
          },
          families: { total: 0, pending: 0 },
        };
      }
      for (let i = 0; i < this.invidata.people.length; i++) {
        const person = this.invidata.people[i];
        const family = this.invidata.families.find(
          (x) => x.id == person.family_id
        );
        const group = this.invidata.groups.find((x) => x.id == family.group_id);
        if (!group) continue;
        const owner = this.invidata.owners.find((x) => x.id == group.owner_id);
        if (!owner) continue;
        byOwner[owner.name].people.total++;
        byOwner["total"].people.total++;
        if (person.confirmed == "Y") {
          byOwner[owner.name].people.confirmed++;
          byOwner["total"].people.confirmed++;
        }
        if (person.confirmed == "N") {
          byOwner[owner.name].people.not_coming++;
          byOwner["total"].people.not_coming++;
        }
        if (person.confirmed != "Y" && person.confirmed != "N") {
          byOwner[owner.name].people.pending++;
          byOwner["total"].people.pending++;
        }
      }

      for (let i = 0; i < this.invidata.families.length; i++) {
        const family = this.invidata.families[i];
        const group = this.invidata.groups.find((x) => x.id == family.group_id);
        const owner = this.invidata.owners.find((x) => x.id == group.owner_id);

        if (!owner) continue;
        byOwner[owner.name].families.total++;
        byOwner["total"].families.total++;
        if (family.invitation_sent != "Y") {
          byOwner[owner.name].families.pending++;
          byOwner["total"].families.pending++;
        }
      }

      return byOwner;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchAll");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
