<template>
  <div>
    <!--saving dialog-->
    <v-dialog v-model="loading2" width="100">
      <v-card>
        <v-card-text
          style="margin: auto; text-align: center; padding-top: 20px"
        >
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- loading -->
    <v-progress-linear
      class="mt-3"
      v-if="loading"
      indeterminate
    ></v-progress-linear>

    <!-- form -->
    <div v-else style="margin-top: 10px; margin-bottom: 60px">
      <v-text-field
        label="Family Name"
        v-model="family2.name"
        append-icon="mdi-history"
        @click:append="family2.name = default_name"
      ></v-text-field>
      <v-text-field
        label="Nombre Invitación"
        v-model="family2.name_override"
      ></v-text-field>

      <v-textarea
        label="Address"
        outlined
        rows="2"
        v-model="family2.address"
      ></v-textarea>

      <ToggleGroup
        title="Invitación enviada"
        v-model="family2.invitation_sent"
        :items="[
          { icon: 'mdi-email', color: 'red', value: 'N' },
          { icon: 'mdi-email', color: 'green', value: 'Y' },
        ]"
      />

      <v-textarea
        label="Comments"
        outlined
        rows="2"
        auto-grow
        v-model="family2.comments"
      ></v-textarea>

      <!-- switches -->

      <!-- regalo -->
      <div>
        <v-row>
          <v-col>
            <v-switch
              true-value="Y"
              false-value="N"
              label="Nos han hecho regalo"
              v-model="family2.regalado"
            ></v-switch>
          </v-col>
          <v-col v-if="family2.regalado">
            <v-switch
              true-value="Y"
              false-value="N"
              label="Agradecido"
              v-model="family2.regalo_thanked"
            ></v-switch>
          </v-col>
        </v-row>
        <div v-if="family2.regalado">
          <v-textarea
            label="Notas regalo"
            v-model="family2.regalo_notes"
            rows="2"
            outlined
          ></v-textarea>
        </div>
      </div>

      <v-switch
        true-value="Y"
        false-value="N"
        v-model="family2.hide"
        label="Archivar (ocultar)"
      ></v-switch>

      <div>
        <p>Nota: Para cambiar los miembros de la familia, ábrelos uno a uno</p>
      </div>

      <!-- people in this family -->
      <div>
        <PersonListItem
          v-for="person in people.filter((x) => x.family_id == id)"
          :key="'person::' + person.id"
          :value="person"
        />
      </div>
    </div>

    <!-- save -->
    <div
      style="
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 60px;
        padding: 15px 10px;
        background-color: white;
        border-top: 1px solid #ccc;
      "
    >
      <div style="max-width: 400px; margin: auto">
        <v-btn block color="primary" depressed @click="save">Save</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/assets/api";
import { mapState } from "vuex";
import ToggleGroup from "@/components/ToggleGroup.vue";
import PersonListItem from "../PeopleList/PersonListItem.vue";

//import utils from "@/assets/utils";
export default {
  components: {
    ToggleGroup,
    PersonListItem,
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  watch: {
    family(newValue) {
      this.family2 = JSON.parse(JSON.stringify(newValue));
      let g = this.invidata.groups.find((x) => x.id == newValue.group_id);
      if (g) this.owner_id = g.owner_id;
    },
  },
  computed: {
    ...mapState(["invidata"]),
    people() {
      return this.$store.getters.people;
    },
    default_name() {
      let people_in_family = this.invidata.people
        .filter((x) => x.family_id == this.id)
        .map((x) => x.full_name);
      let name = people_in_family.join(" + ");
      if (people_in_family.length < 2) {
        name = "Family of " + name;
      }

      return name;
    },
    family() {
      //deafult name is used if no othe name is present

      for (let i = 0; i < this.invidata.families.length; i++) {
        let family = this.invidata.families[i];

        if (family.id == this.id) {
          if (!family.name) family.name = this.default_name;
          return family;
        }
      }

      return {
        id: this.id,
        name: this.default_name,
      }; //it seems this is a new family (or was not created at least)
    },

    group_options() {
      return this.invidata.groups
        .map((x) => {
          return {
            text: x.name,
            value: x.id,
            owner: this.invidata.owners.find((o) => o.id == x.owner_id)?.name,
          };
        })
        .sort((a, b) => (b.owner + b.text).localeCompare(a.owner + a.text));
    },
  },
  data() {
    return {
      loading: false,
      loading2: false,
      family2: {},
      owner_id: "",
      showGroupPicker: false,
    };
  },

  methods: {
    async save() {
      this.loading2 = true;
      let x = await api.apiFetch("/families", {
        method: "POST",
        body: JSON.stringify(this.family2),
      });
      if (!x.ok) {
        console.error("Error saving");
      } else {
        let j = await x.json();
        console.log("Saved", j);

        //propagate change:
        let xx = JSON.parse(JSON.stringify(this.invidata));

        let index = xx.families.findIndex((x) => x.id == this.id);

        xx.families[index] = JSON.parse(JSON.stringify(this.family2));

        this.$store.commit("SET_INVIDATA", xx);
        this.$emit("saved");
      }
      this.loading2 = false;
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchAll");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
