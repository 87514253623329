<template>
  <v-card class="pa-0" height="400">
    <v-card-title class="pb-2">
      <v-text-field
        id="searchFamily"
        @focus="$event.target.select()"
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        v-model="searchText"
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon text @click="cancel"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text class="pa-3 pt-0">
      <p
        style="
          color: grey;
          font-size: 85%;
          margin-left: 15px;
          margin-bottom: 0px;
        "
      >
        Go to the Google Spreadsheet to create/edit groups.
      </p>
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="group in filteredList"
            :key="group.id"
            @click="save(group.id)"
            :style="{
              backgroundColor:
                group.id == value ? 'rgba(144, 213, 240, 0.23)' : '',
            }"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ group.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="error" @click="cancel">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import utils from "@/assets/utils";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(n) {
      this.group_id = n;
    },
  },
  computed: {
    ...mapState(["invidata"]),
    filteredList() {
      let x = [];
      for (let i = 0; i < this.invidata.groups.length; i++) {
        const group = this.invidata.groups[i];

        let text = [group.name].join(" ");
        if (utils.searchMatch(this.searchText, text)) {
          x.push(group);
        }
      }
      return x;
    },
  },
  data() {
    return {
      searchText: "",
      group_id: "",
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save(id) {
      this.$emit("input", id);
      this.cancel(); //just so we close
    },
  },
  mounted() {
    this.group_id = this.value;
    document.getElementById("searchFamily").focus();
  },
};
</script>

<style lang="scss" scoped></style>
