<template>
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary" style="max-height: 60px">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Persona {{ person.id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="false">
            <v-btn dark text @click="dialog = false"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-3">
          <PersonPage :id="person.id" @saved="dialog = false" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-list-item @click="dialog = !dialog" class="px-0">
      <v-list-item-avatar class="ma-0 pa-0">
        <v-icon :color="color">{{ icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <!-- person name-->
        <v-list-item-title>
          {{ person.full_name }}
        </v-list-item-title>
        <!-- person info -->
        <v-list-item-subtitle v-if="person.alergias != ''">
          {{ person.alergias }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="person.comments != ''">
          {{ person.comments }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="false">
        <v-icon color="grey lighten-1">mdi-information</v-icon>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import PersonPage from "../Edit/PersonPage.vue";
export default {
  components: {
    PersonPage,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.person = newValue;
      },
    },
    person: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  computed: {
    icon() {
      if (this.person.chosen == "Y") return "mdi-star";
      if (this.person.chosen == "N") return "mdi-account";
      return "mdi-star-half-full"; //unclear
    },
    color() {
      if (this.person.confirmed == "Y") return "green";
      if (this.person.confirmed == "N") return "red";
      return "purple";
    },
  },
  data() {
    return {
      person: {},
      dialog: false,
    };
  },
  mounted() {
    this.person = this.value;
  },
};
</script>

<style lang="scss" scoped></style>
