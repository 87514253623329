<template>
  <div>
    <!-- owners -->
    <div>
      <TagsSelector
        :items="ownerChips"
        color="red"
        v-model="filteredOwners"
        :multiple="false"
        small
      />
    </div>

    <!-- groups -->
    <div>
      <TagsSelector
        :items="groupChips"
        color="red"
        v-model="filteredGroups"
        :multiple="false"
        small
      />
    </div>
  </div>
</template>

<script>
import TagsSelector from "@/components/TagsSelector.vue";

import { mapState } from "vuex";
export default {
  components: {
    TagsSelector,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    filteredOwners: {
      deep: true,
      handler() {
        this.filteredGroups = [];
        this.$emit("input", {
          owners: this.filteredOwners,
          groups: this.filteredGroups,
        });
      },
    },
    filteredGroups: {
      deep: true,
      handler() {
        this.$emit("input", {
          owners: this.filteredOwners,
          groups: this.filteredGroups,
        });
      },
    },
  },
  data() {
    return {
      filteredOwners: [],
      filteredGroups: [],
    };
  },
  computed: {
    ...mapState(["invidata"]),

    ownerChips() {
      let x = [];

      let ppo = {}; //people-per-owner
      for (let i = 0; i < this.items.length; i++) {
        const family = { ...this.items[i] };

        for (let j = 0; j < family.people.length; j++) {
          const person = family.people[j];
          if (!ppo[person.owner.id]) ppo[person.owner.id] = 0;
          if (person.confirmed == "Y") ppo[person.owner.id]++;
        }
      }

      for (let i = 0; i < this.items.length; i++) {
        const family = { ...this.items[i] };
        for (let j = 0; j < family.people.length; j++) {
          const person = family.people[j];

          if (!x.find((a) => a.value == person.owner.id)) {
            x.push({
              value: person.owner.id,
              title: person.owner.name + " (" + ppo[person.owner.id] + ")",
              color: "primary",
            });
          }
        }
      }

      return x;
    },
    groupChips() {
      let x = [];

      let ppg = {}; //people-per-group
      for (let i = 0; i < this.items.length; i++) {
        const family = { ...this.items[i] };
        for (let j = 0; j < family.people.length; j++) {
          const person = family.people[j];

          if (!ppg[person.group.id]) ppg[person.group.id] = 0;
          if (person.confirmed == "Y") ppg[person.group.id]++;
        }
      }

      for (let i = 0; i < this.items.length; i++) {
        const family = { ...this.items[i] };

        for (let j = 0; j < family.people.length; j++) {
          const person = family.people[j];

          if (!x.find((a) => a.value == person.group.id)) {
            //add only if not yet added
            if (
              this.filteredOwners.length == 0 ||
              this.filteredOwners.includes(person.owner.id)
            ) {
              //add only if this owner is selected, or no owner is selected
              x.push({
                value: person.group.id,
                title: person.group.name + " (" + ppg[person.group.id] + ")",
                color: "primary",
              });
            }
          }
        }
      }

      return x;
    },
  },
};
</script>

<style lang="scss" scoped></style>
