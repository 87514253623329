<template>
  <div style="margin: 30px 5px">
    <div class="wrapper" v-if="!expired">
      <div class="box" v-for="(item, k) of countdown" :key="k">
        <div class="number">{{ item.value }}</div>
        <div class="label">{{ item.value == 1 ? item.one : item.many }}</div>
      </div>
    </div>
    <div v-else style="text-align: center; margin: auto">Timer expired</div>
    <div style="text-align: center; font-size: 90%">
      {{ formatDate(value) }}
    </div>
    <p id="demo"></p>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "2023-11-24 0:00:00",
    },
  },
  data() {
    return {
      countdown: {
        days: { value: "00", many: "días", one: "día" },
        hours: { value: "00", many: "hours", one: "hora" },
        minutes: { value: "00", many: "minutos", one: "minuto" },
        seconds: { value: "00", many: "segundos", one: "segundo" },
      },
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
    };
  },
  methods: {
    formatDate(d) {
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      let date = new Date(d);
      return date.toLocaleDateString("es-ES", options);
    },
    pad(v) {
      return v < 10 ? "0" + v : v;
    },
    refreshTime(interval) {
      // Set the date we're counting down to
      var countDownDate = new Date(this.value).getTime();
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result
      this.countdown.days.value = this.pad(days);
      this.countdown.hours.value = this.pad(hours);
      this.countdown.minutes.value = this.pad(minutes);
      this.countdown.seconds.value = this.pad(seconds);

      // If the count down is finished, write some text
      if (distance < 0) {
        this.expired = true;
        clearInterval(interval);
      }
    },

    initTimer() {
      this.refreshTime();
      // Update the count down every 1 second
      var x = setInterval(() => {
        this.refreshTime(x);
      }, 1000);
    },
  },
  mounted() {
    this.initTimer();
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;

  justify-content: space-evenly;

  max-width: 400px;
  margin: auto;
}
.box {
  flex: 1 1 0px;
  width: 0px;

  background-color: lightblue;
  color: darkblue;

  font-size: 2rem;
  margin: auto;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
}

.box .number {
  padding: 10px 20px 0px 20px;
}
.box .label {
  font-size: 0.75rem;
  margin-bottom: 5px;
}
</style>
