var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"800px","margin":"auto"}},[_c('v-dialog',{attrs:{"width":"100"},model:{value:(_vm.loading2),callback:function ($$v) {_vm.loading2=$$v},expression:"loading2"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"margin":"auto","text-align":"center","padding-top":"20px"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{staticClass:"mt-3",attrs:{"indeterminate":""}}):_vm._e(),(!_vm.loading)?_c('div',{staticStyle:{"margin-bottom":"60px"}},[_c('v-text-field',{attrs:{"label":"name","readonly":_vm.readonly},model:{value:(_vm.person2.full_name),callback:function ($$v) {_vm.$set(_vm.person2, "full_name", $$v)},expression:"person2.full_name"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","flex-wrap":"wrap"}},[_c('ToggleGroup',{attrs:{"title":"Favorite","default":'Null',"readonly":_vm.readonly,"items":[
          { icon: 'mdi-account', color: 'black', value: 'N' },
          { icon: 'mdi-star-half-full', color: 'black', value: 'Null' },
          { icon: 'mdi-star', color: 'black', value: 'Y' },
        ]},model:{value:(_vm.person2.chosen),callback:function ($$v) {_vm.$set(_vm.person2, "chosen", $$v)},expression:"person2.chosen"}}),_c('ToggleGroup',{attrs:{"title":"Confirmed","default":'Null',"readonly":_vm.readonly,"items":[
          { icon: 'mdi-checkbox-blank-circle', color: 'red', value: 'N' },
          {
            icon: 'mdi-checkbox-blank-circle',
            color: 'purple',
            value: 'Null',
          },
          { icon: 'mdi-checkbox-blank-circle', color: 'green', value: 'Y' },
        ]},model:{value:(_vm.person2.confirmed),callback:function ($$v) {_vm.$set(_vm.person2, "confirmed", $$v)},expression:"person2.confirmed"}}),_c('ToggleGroup',{attrs:{"title":"Bus?","default":'Null',"readonly":_vm.readonly,"items":[
          { icon: 'mdi-bus', color: 'red', value: 'N' },
          {
            icon: 'mdi-bus',
            color: 'purple',
            value: 'Null',
          },
          { icon: 'mdi-bus', color: 'green', value: 'Y' },
        ]},model:{value:(_vm.person2.bus),callback:function ($$v) {_vm.$set(_vm.person2, "bus", $$v)},expression:"person2.bus"}}),_c('ToggleGroup',{attrs:{"title":"Alergias","default":'Null',"readonly":_vm.readonly,"items":[
          { icon: 'mdi-food-off', color: 'red', value: 'N' },
          {
            icon: 'mdi-food',
            color: 'purple',
            value: 'Null',
          },
          { icon: 'mdi-food', color: 'green', value: 'Y' },
        ]},model:{value:(_vm.person2.has_alergias),callback:function ($$v) {_vm.$set(_vm.person2, "has_alergias", $$v)},expression:"person2.has_alergias"}})],1),(_vm.person2.has_alergias != 'N')?_c('v-textarea',{staticClass:"mt-2",attrs:{"outlined":"","readonly":_vm.readonly,"rows":"2","label":"Alergias a..."},model:{value:(_vm.person2.alergias),callback:function ($$v) {_vm.$set(_vm.person2, "alergias", $$v)},expression:"person2.alergias"}}):_vm._e(),_c('v-select',{attrs:{"label":"Menu","readonly":_vm.readonly,"outlined":"","items":['ADULTO', 'INFANTIL', 'BEBE', 'STAFF']},model:{value:(_vm.person2.menu),callback:function ($$v) {_vm.$set(_vm.person2, "menu", $$v)},expression:"person2.menu"}}),_c('div',[_c('v-row',[_c('v-col',[(_vm.invidata.families)?_c('v-text-field',{attrs:{"label":"Familia","readonly":"","value":_vm.familyName,"hide-details":""},on:{"click":function($event){_vm.showFamilyPicker = true && !_vm.readonly}}}):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500","fullscreen":""},model:{value:(_vm.showFamilyPicker),callback:function ($$v) {_vm.showFamilyPicker=$$v},expression:"showFamilyPicker"}},[_c('FamilyPicker',{on:{"cancel":function($event){_vm.showFamilyPicker = false}},model:{value:(_vm.person2.family_id),callback:function ($$v) {_vm.$set(_vm.person2, "family_id", $$v)},expression:"person2.family_id"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"owner","readonly":_vm.readonly,"items":_vm.invidata.owners.map((x) => {
                return { text: x.name, value: x.id };
              })},model:{value:(_vm.person2.owner_id),callback:function ($$v) {_vm.$set(_vm.person2, "owner_id", $$v)},expression:"person2.owner_id"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":"","label":"Group","value":_vm.invidata.groups.find((x) => x.id == _vm.person2.group_id)?.name},on:{"click":function($event){_vm.showGroupPicker = true && !_vm.readonly}}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500","fullscreen":""},model:{value:(_vm.showGroupPicker),callback:function ($$v) {_vm.showGroupPicker=$$v},expression:"showGroupPicker"}},[_c('GroupPicker',{on:{"cancel":function($event){_vm.showGroupPicker = false}},model:{value:(_vm.person2.group_id),callback:function ($$v) {_vm.$set(_vm.person2, "group_id", $$v)},expression:"person2.group_id"}})],1)],1)],1)],1),_c('v-textarea',{attrs:{"readonly":_vm.readonly,"outlined":"","rows":"2","label":"Comments"},model:{value:(_vm.person2.comments),callback:function ($$v) {_vm.$set(_vm.person2, "comments", $$v)},expression:"person2.comments"}}),_c('v-switch',{attrs:{"true-value":"Y","false-value":"N","readonly":_vm.readonly,"label":"Archivar (ocultar)"},model:{value:(_vm.person2.hide),callback:function ($$v) {_vm.$set(_vm.person2, "hide", $$v)},expression:"person2.hide"}}),(false)?_c('div',{staticStyle:{"margin-top":"80px"}},[_c('pre',[_vm._v(_vm._s(JSON.stringify(_vm.person2, null, 2)))])]):_vm._e()],1):_vm._e(),_c('div',{staticStyle:{"position":"fixed","bottom":"0px","left":"0px","right":"0px","height":"60px","padding":"15px 10px","background-color":"white","border-top":"1px solid #ccc"}},[_c('div',{staticStyle:{"max-width":"400px","margin":"auto"}},[_c('v-btn',{attrs:{"block":"","color":"primary","depressed":"","disabled":_vm.readonly},on:{"click":_vm.save}},[_vm._v("Save")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }