<template>
  <div>
    <!-- asistencia -->
    <div>
      <h3>Asistencia</h3>
      <v-simple-table class="mtable">
        <template v-slot:default>
          <thead class="text-left">
            <tr>
              <th></th>
              <!-- owner -->
              <th>Sí</th>
              <th>No</th>
              <th>Duda</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ people.filter((x) => x.confirmed == "Y").length }}</td>
              <td>{{ people.filter((x) => x.confirmed == "N").length }}</td>
              <td>
                {{
                  people.filter((x) => x.confirmed != "Y" && x.confirmed != "N")
                    .length
                }}
              </td>
              <td>{{ people.length }}</td>
            </tr>
            <tr v-for="owner in owners" :key="owner.id">
              <td>{{ owner.name }}</td>
              <td>
                {{
                  people.filter(
                    (x) => x.owner.id == owner.id && x.confirmed == "Y"
                  ).length
                }}
              </td>
              <td>
                {{
                  people.filter(
                    (x) => x.owner.id == owner.id && x.confirmed == "N"
                  ).length
                }}
              </td>
              <td>
                {{
                  people.filter(
                    (x) =>
                      x.owner.id == owner.id &&
                      x.confirmed != "Y" &&
                      x.confirmed != "N"
                  ).length
                }}
              </td>
              <td>
                {{ people.filter((x) => x.owner.id == owner.id).length }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <!-- families -->
    <div style="margin-top: 30px">
      <h3>Invitaciones</h3>
      <v-simple-table class="mtable">
        <template v-slot:default>
          <thead class="text-left">
            <tr>
              <th></th>
              <!-- owner -->
              <th>Pendiente</th>
              <th>Enviada</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>
                {{ families.filter((x) => x.invitation_sent != "Y").length }}
              </td>
              <td>
                {{ families.filter((x) => x.invitation_sent == "Y").length }}
              </td>

              <td>
                {{ families.filter((x) => x.invitation_sent).length }}
              </td>
            </tr>
            <tr v-for="owner in owners" :key="owner.id">
              <td>{{ owner.name }}</td>
              <td>
                {{
                  families.filter(
                    (x) => x.owner.id == owner.id && x.invitation_sent != "Y"
                  ).length
                }}
              </td>
              <td>
                {{
                  families.filter(
                    (x) => x.owner.id == owner.id && x.invitation_sent == "Y"
                  ).length
                }}
              </td>
              <td>
                {{ families.filter((x) => x.owner.id == owner.id).length }}
              </td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <!-- menus -->

    <div style="margin-top: 30px">
      <h3>Menús</h3>
      <v-simple-table class="mtable">
        <template v-slot:default>
          <thead class="text-left">
            <tr>
              <th></th>
              <th>Confirmados</th>
              <th>Duda</th>
              <th v-if="showPrices">Precio</th>
              <th v-if="showPrices">Precio Confirmados</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ people.filter((x) => x.confirmed == "Y").length }}</td>
              <td>{{ people.filter((x) => x.confirmed != "Y").length }}</td>
              <td v-if="showPrices"></td>
              <td v-if="showPrices">{{ people.length }}</td>
            </tr>
            <tr v-for="menu in menus" :key="menu">
              <td>{{ menu }}</td>
              <td>
                {{
                  people.filter((x) => x.confirmed == "Y" && x.menu == menu)
                    .length
                }}
              </td>
              <td>
                {{
                  people.filter((x) => x.confirmed != "Y" && x.menu == menu)
                    .length
                }}
              </td>
              <td v-if="showPrices">
                {{
                  precios.find((x) => x.id == menu)
                    ? precios.find((x) => x.id == menu).precio
                    : 0
                }}
              </td>
              <td v-if="showPrices">
                {{
                  people.filter((x) => x.confirmed == "Y" && x.menu == menu)
                    .length *
                  (precios.find((x) => x.id == menu)
                    ? precios.find((x) => x.id == menu).precio
                    : 0)
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <!-- bus -->
    <div style="margin-top: 30px">
      <h3>Bus</h3>
      <span
        >De los {{ people.filter((x) => x.confirmed == "Y").length }} que han
        confirmado:</span
      >
      <ul>
        <li>
          Sí:
          {{ people.filter((x) => x.confirmed == "Y" && x.bus == "Y").length }}
        </li>
        <li>
          No:
          {{ people.filter((x) => x.confirmed == "Y" && x.bus == "N").length }}
        </li>
        <li>
          Nu sé:
          {{
            people.filter(
              (x) => x.confirmed == "Y" && x.bus != "Y" && x.bus != "N"
            ).length
          }}
        </li>
      </ul>
    </div>
    <br />
    <br />
    <pre>{{ JSON.stringify(metrics, null, 2) }}</pre>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["invidata"]),
    owners() {
      return this.invidata.owners;
    },
    menus() {
      let x = [];
      for (let i = 0; i < this.people.length; i++) {
        const person = this.people[i];
        if (!x.includes(person.menu)) x.push(person.menu);
      }
      return x;
    },
    people() {
      return this.$store.getters.people;
    },
    families() {
      //TODO: now same family might have people with different owners and groups - make sure all good
      let output = [];

      for (let i = 0; i < this.invidata.people.length; i++) {
        const person = this.invidata.people[i];
        if (person.hide) continue;

        let family = this.invidata.families.find(
          (x) => x.id == person.family_id
        );
        if (family.hide) continue; //skip hide

        let group = this.invidata.groups.find((x) => x.id == person.group_id);
        let owner = this.invidata.owners.find((x) => x.id == person.owner_id);

        family.group = group;
        family.owner = owner;

        if (!output.find((x) => x.id == family.id)) {
          output.push(family);
        }
      }
      return output;
    },
    metrics() {
      //-------------------------

      let byOwner = {};

      let owners = this.invidata.owners.map((x) => x.name);
      owners.unshift("total");

      for (let i = 0; i < owners.length; i++) {
        const o = owners[i];
        byOwner[o] = {
          people: {
            total: 0,
            confirmed: 0,
            pending: 0,
            not_confirmed: 0,
          },
          families: { total: 0, pending: 0 },
        };
      }

      for (let i = 0; i < this.people.length; i++) {
        const person = this.people[i];
        byOwner[person.owner.name].people.total++;
        byOwner["total"].people.total++;
        if (person.confirmed == "Y") {
          byOwner[person.owner.name].people.confirmed++;
          byOwner["total"].people.confirmed++;
        }
        if (person.confirmed == "N") {
          byOwner[person.owner.name].people.not_confirmed++;
          byOwner["total"].people.not_confirmed++;
        }
        if (person.confirmed != "Y" && person.confirmed != "N") {
          byOwner[person.owner.name].people.pending++;
          byOwner["total"].people.pending++;
        }
      }

      for (let i = 0; i < this.families.length; i++) {
        const family = this.families[i];
        byOwner[family.owner.name].families.total++; // NOTE: now same family might have people with different owners and groups
        byOwner["total"].families.total++;
        if (family.invitation_sent != "Y") {
          byOwner[family.owner.name].families.pending++;
          byOwner["total"].families.pending++;
        }
      }

      return byOwner;
    },
  },
  data() {
    return {
      loading: false,
      showPrices: false,
      precios: [
        { id: "ADULTO", precio: 123.75 },
        { id: "INFANTIL", precio: 27.5 },
        { id: "STAFF", precio: 61.875 },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("fetchAll");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.mtable {
  max-width: 400px;
}
</style>
