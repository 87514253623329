/*import * as uuid from "uuid";

function uuidv4() {
  return uuid.v4();
}
*/

function searchMatchAux(searchText, searchInText) {
  //this one cleans up the special characters, so no need to type them write. Also removes the lower/upper case matching.
  //Best to use the searchMatch_Smart() one
  searchText = searchText == undefined ? "" : searchText;
  searchText = searchText == null ? "" : searchText;

  searchInText = searchInText
    .toLowerCase()
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/ó/g, "o")
    .replace(/ú/g, "u")
    .replace(/ñ/g, "n");

  searchText = searchText
    .toLowerCase()
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/ó/g, "o")
    .replace(/ú/g, "u")
    .replace(/ñ/g, "n");

  if (searchInText.indexOf(searchText) > -1) {
    return true;
  } else {
    return false;
  }
}

function searchMatch(searchText, searchInText) {
  //this one checks each word separately, so it's easier to search for stuff
  searchText = searchText == undefined ? "" : searchText;
  searchText = searchText == null ? "" : searchText;

  var words = searchText.split(" ");

  var all_good = true;

  words.forEach((word) => {
    if (!searchMatchAux(word, searchInText)) {
      all_good = false;
    }
  });

  return all_good;
}

module.exports = {
  searchMatch,
  //  uuidv4,
};
